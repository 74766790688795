<template>
  <v-btn v-bind="$attrs" v-on="$listeners" :color="colorBottom">
    <v-icon class="mr-1" :color="colorIcon"> {{ iconName }} </v-icon>
    {{ bottomName }}
  </v-btn>
</template>

<script>
export default {
  props: {
    bottomName: {
      type: String
    },
    colorBottom: {
      type: String
    },
    iconName: {
      type: String
    },
    colorIcon: {
      type: String
    }
  }
};
</script>

<style></style>
