const servicesFiles = require.context(".", true, /\Service.js$/);
// const services = {};
// const relationships = {};
const methods = {};
const expression = /(?<=\/).*(?=\.js)/;

servicesFiles.keys().forEach(fileName => {
  const keyName = fileName.match(expression)[0].split("Service")[0];

  if (keyName == "base") return;

  const allServices = { [keyName]: servicesFiles(fileName).default };

  Object.keys(allServices[keyName]().relationship).map(item => {
    Object.assign(methods, { [item]: allServices[keyName]()[item]() });
  });

  //console.log({ [allServices[keyName]().relationship]: keyName });

  // Object.assign(services, allServices);
  // Object.assign(relationships, allServices[keyName]().relationship);
  // methods[relationships.anos] = allServices[keyName]().anos;

  // Object.keys(relationships).map(item => {
  //   console.log(item, keyName);
  // });
});
export default methods;
