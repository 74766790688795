<template>
  <BaseBottom
    v-bind="$attrs"
    v-on="$listeners"
    text
    :bottomName="name"
    :iconName="icon"
  />
</template>

<script>
import BaseBottom from "./BaseBottom.vue";
export default {
  components: {
    BaseBottom
  },

  data() {
    return {
      name: "FILTRO",
      icon: "mdi-tune-variant"
    };
  }
};
</script>

<style></style>
