<template>
  <v-container class="container-card">
    <v-card>
      <ConsultaEmpresaProcessa />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import ConsultaEmpresaProcessa from "@/components/sistema/processos/empresaProcessa/ConsultaEmpresaProcessa";
export default {
  name: "EmpresaProcessa",

  components: {
    ConsultaEmpresaProcessa
  },

  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    })
  },

  mounted() {
    this.fetchView("Empresa Processa");
  }
};
</script>
