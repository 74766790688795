var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{attrs:{"headers":_vm.headers,"items":_vm.empresaProcessa,"loading":_vm.loading,"paginate":true,"sort-by":"nome_empresa"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Processos")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('RegisterBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(166),expression:"166"}],on:{"click":function($event){(_vm.dialog = true), (_vm.labelBtn = 'Salvar')}}}),_c('FiltroEmpresaProcessaVue',{on:{"selectedFilters":function($event){return _vm.getEmpresaProcessa($event)}}}),_c('ModalCadastroEmpresaProcessa',{attrs:{"dialog":_vm.dialog,"labelBtn":_vm.labelBtn,"editVinculo":_vm.vinculaEmpresa},on:{"close":function($event){_vm.dialog = false}}})],1)]},proxy:true},{key:"item.nome_empresa",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.nome_empresa))+" ")]}},{key:"item.descricao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.descricao))+" ")]}},{key:"item.tipo_evento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.tipo_evento))+" ")]}},{key:"item.processa",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.statusFormatedIcon(item.processa).color}},[_vm._v(" "+_vm._s(_vm.statusFormatedIcon(item.processa).icon)+" ")])]}},{key:"item.ativo",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusProcesso(item.ativo) == 'Ativo' ? 'green' : 'red',"dark":""}},[_vm._v(" "+_vm._s(_vm.statusProcesso(item.ativo))+" ")])]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('IconBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(165),expression:"165"}],attrs:{"name":'edit'},on:{"click":function($event){return _vm.editItem(item)}}}),_c('IconBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(167),expression:"167"}],attrs:{"name":'delete'},on:{"click":function($event){return _vm.deleteItem(item)}}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }