/**
 * Formata data para padrão comum de exibição
 * @param {string | Date} date
 * @param {string} monthFormat - "numeric | long"
 */
function formatDate(date, monthFormat = "numeric") {
  if (!date) return "Não informado";
  const options = { year: "numeric", month: monthFormat, day: "numeric" };
  const newDate = new Date(date);

  return newDate.toLocaleDateString("pt-br", options);
}

function formatDateAndTime(date) {
  if (!date) return "Não informado";
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false
  };

  const newDate = new Date(date);

  return new Intl.DateTimeFormat("pt-BR", options).format(newDate);
}

const dateMixin = {
  methods: {
    format(value) {
      return formatDate(value);
    }
  },

  filters: {
    BrazilianStandardDate(value) {
      return formatDate(value);
    },
    BrazilianStandardDateAndTime(value) {
      return formatDateAndTime(value);
    }
  }
};

export default dateMixin;
