var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.multiple)?_c('div',[_c('v-select',_vm._g(_vm._b({ref:"select",attrs:{"items":_vm.items,"value-comparator":_vm.comparator,"chips":"","multiple":_vm.multiple,"loading-text":"Carregando... Aguarde","no-data-text":"Sem dados disponíveis"},on:{"change":function($event){return _vm.onChanged($event)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":_vm.toggle}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.selectedItems.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Selecionar Todos ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(_vm._f("titleCase")(item[_vm.itemText])))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectedItems.length - 1)+") ")]):_vm._e()]}}],null,false,1214626224),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},'v-select',_vm.$attrs,false),_vm.$listeners))],1):_c('div',[_c('v-autocomplete',_vm._g(_vm._b({ref:"select",attrs:{"items":_vm.items,"value-comparator":_vm.comparator,"chips":"","multiple":_vm.multiple,"model":_vm.selectedItems,"loading-text":"Carregando... Aguarde","no-data-text":"Sem dados disponíveis"},on:{"change":function($event){return _vm.onChanged($event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.isCompany)?[(index === 0)?_c('v-chip',[_c('BaseSegmento',{attrs:{"segmento":item.id_band}}),_vm._v(" "+_vm._s(item.apelido || item.descricao)+" ")],1):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectedItems.length - 1)+") ")]):_vm._e()]:[(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(_vm._f("titleCase")(item[_vm.itemText])))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectedItems.length - 1)+" others) ")]):_vm._e()]]}},{key:"item",fn:function(ref){
var item = ref.item;
return [(_vm.isCompany)?[[_c('BaseSegmento',{staticClass:"pl-3",attrs:{"segmento":item.id_band}}),_vm._v(" "+_vm._s(item.apelido || item.descricao)+" ")]]:[_vm._v(" "+_vm._s(_vm._f("titleCase")(item[_vm.itemText]))+" ")]]}},(_vm.hasStatus())?{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.filterItem.color},on:{"click":_vm.changeStatusFilter}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.filterItem.icon)+" ")])]}}],null,false,982906952)},[_c('span',[_vm._v(_vm._s(_vm.filterItem.tip))])])]},proxy:true}:null],null,true)},'v-autocomplete',_vm.$attrs,false),_vm.$listeners))],1)])}
var staticRenderFns = []

export { render, staticRenderFns }