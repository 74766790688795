<template>
  <div>
    <div v-if="multiple">
      <!-- \\\\\\\\\\\\\\\\\\\\\\ -->

      <v-select
        ref="select"
        :items="items"
        :value-comparator="comparator"
        v-bind="$attrs"
        chips
        :multiple="multiple"
        v-model="selectedItems"
        v-on="$listeners"
        loading-text="Carregando... Aguarde"
        no-data-text="Sem dados disponíveis"
        @change="onChanged($event)"
      >
        <template v-slot:prepend-item>
          <v-list-item ripple @mousedown.prevent @click="toggle">
            <v-list-item-action>
              <v-icon
                :color="selectedItems.length > 0 ? 'indigo darken-4' : ''"
              >
                {{ icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Selecionar Todos
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>

        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0">
            <span>{{ item[itemText] | titleCase }}</span>
          </v-chip>
          <span v-if="index === 1" class="grey--text text-caption">
            (+{{ selectedItems.length - 1 }})
          </span>
        </template>
      </v-select>
    </div>

    <div v-else>
      <v-autocomplete
        ref="select"
        :items="items"
        :value-comparator="comparator"
        v-bind="$attrs"
        chips
        :multiple="multiple"
        :model="selectedItems"
        v-on="$listeners"
        loading-text="Carregando... Aguarde"
        no-data-text="Sem dados disponíveis"
        @change="onChanged($event)"
      >
        <template v-slot:selection="{ item, index }">
          <template v-if="isCompany">
            <v-chip v-if="index === 0">
              <BaseSegmento :segmento="item.id_band" />
              {{ item.apelido || item.descricao }}
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ selectedItems.length - 1 }})
            </span>
          </template>

          <template v-else>
            <v-chip v-if="index === 0">
              <span>{{ item[itemText] | titleCase }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ selectedItems.length - 1 }} others)
            </span>
          </template>
        </template>

        <template v-slot:item="{ item }">
          <template v-if="isCompany">
            <template>
              <BaseSegmento class="pl-3" :segmento="item.id_band" />
              {{ item.apelido || item.descricao }}
            </template>
          </template>

          <template v-else>
            {{ item[itemText] | titleCase }}
          </template>
        </template>

        <template v-if="hasStatus()" v-slot:append>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                :color="filterItem.color"
                @click="changeStatusFilter"
              >
                {{ filterItem.icon }}
              </v-icon>
            </template>
            <span>{{ filterItem.tip }}</span>
          </v-tooltip>
        </template>
      </v-autocomplete>
    </div>
  </div>
</template>
<script>
import BaseSegmento from "@/components/shared/BaseSegmento";
import comparator from "@/mixins/comparatorValue";

export default {
  name: "BaseSelectService",
  components: { BaseSegmento },
  mixins: [comparator],
  props: {
    service: {
      type: String,
      default: ""
    },
    filters: {
      type: Object
    },
    isCompany: {
      type: Boolean,
      default: false
    },
    hasCode: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      items: [],
      itemText: [],
      selectedItems: [],
      isActive: true,
      filterStatus: "",
      filterItem: {
        status: "S",
        color: "success",
        tip: "Ativos",
        icon: "mdi-checkbox-marked-circle"
      }
    };
  },

  computed: {
    allItem() {
      return this.selectedItems.length === this.items.length;
    },
    someItem() {
      return this.selectedItems.length > 0 && !this.allItem;
    },
    icon() {
      if (this.allItem) return "mdi-close-box";
      if (this.someItem) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    }
  },

  methods: {
    async fetchService() {
      const [service, relationship] = this.service?.split(".");
      const httpService = require.context("@/services/http/", true, /.js$/);
      const http = httpService(`./${service}.js`).default;
      let response;

      response =
        relationship !== undefined
          ? await http()
              [relationship]()
              .show(
                { per_page: -1, ...this.filters },
                { notification: false },
                { useCache: true }
              )
          : await http().show(
              { per_page: -1, ...this.filters },
              { notification: false },
              { useCache: true }
            );
      this.items = this.hasCode
        ? this.formatCode(response.data.data)
        : response.data.data;
      this.items = this.orderItems(this.items);
    },

    orderItems(items) {
      this.itemText = this.$refs.select.$options.propsData.itemText;
      return items.sort((a, b) => {
        return a[this.itemText] > b[this.itemText] ? 1 : -1;
      });
    },

    formatCode(items) {
      const itemText = this.$refs.select.$options.propsData.itemText;
      const itemValue = this.$refs.select.$options.propsData.itemValue;

      if (this.checkAlreadyFormated(items[0][itemText])) return items;
      return items.map(item => {
        this.service == "sistemaService.funcoes"
          ? (item[itemText] = `${item["cod_funcao"]} - ${item[itemText]}`)
          : (item[itemText] = `${item[itemValue]} - ${item[itemText]}`);

        return item;
      });
    },

    checkAlreadyFormated(item) {
      return !isNaN(item[0]);
    },

    toggle() {
      this.$nextTick(() => {
        if (this.allItem) {
          this.selectedItems = [];
        } else {
          this.selectedItems = this.items.map(item => item.id_empresa);
          this.onChanged(this.selectedItems);
        }
      });
    },

    onChanged(item) {
      this.$emit("onChanged", item);
    },

    hasStatus() {
      return this.filters?.ativo ? true : false;
    },

    changeStatusFilter() {
      this.isActive = !this.isActive;
      this.filterItem.status = this.isActive ? "S" : "N";
      this.filters.ativo = this.isActive ? "S" : "N";
      this.filterItem.color = this.isActive ? "success" : "error";
      this.filterItem.tip = this.isActive ? "ativos" : "inativos";
      this.filterItem.icon = this.isActive
        ? "mdi-checkbox-marked-circle"
        : "mdi-close-circle";
      this.fetchService();
    }
  },

  mounted() {
    this.fetchService();
  },

  watch: {
    service() {
      this.fetchService();
    },
    filters() {
      this.fetchService();
    }
  }
};
</script>
