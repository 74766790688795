<template>
  <v-dialog v-model="dialog" persistent max-width="50%">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            {{ labelBtn != "Salvar" ? "Editar Proceso" : "Cadastrar Proceso" }}
          </span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-select
                  label="Empresa"
                  v-model="formVinculo.id_empresa"
                  :items="getEmpresas"
                  item-text="nome"
                  item-value="id_empresa"
                  :rules="[rules.required]"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-select
                  label="Processo"
                  v-model="formVinculo.id_processo"
                  :items="getProcedures"
                  item-text="descricao"
                  item-value="id_processo"
                  :rules="[rules.required]"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-select
                  label="Tipo Evento"
                  v-model="formVinculo.id_tipo_evento"
                  :rules="[rules.required]"
                  :items="getTiposEvento"
                  item-text="tipo_evento"
                  item-value="id_tipo_evento"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-select
                  label="Processa"
                  v-model="formVinculo.processa"
                  :items="[
                    { text: 'Sim', value: 'S' },
                    { text: 'Não', value: 'N' }
                  ]"
                  :rules="[rules.required]"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close">Fechar</v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="
            labelBtn != 'Salvar' ? editarVinculo() : vincularEmpresaProcesso()
          "
          >{{ labelBtn }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "@/mixins/inputRules";
import serviceProcedure from "@/services/procedures";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ModalCadastroProcessos",

  mixins: [rules],

  props: {
    dialog: {
      type: Boolean,
      default: false
    },

    labelBtn: {
      type: String
    },

    editVinculo: {
      type: Object
    }
  },

  data() {
    return {
      formVinculo: {
        id_empresa: "",
        id_processo: "",
        id_tipo_evento: "",
        processa: ""
      },
      defaultStateForm: {
        id_empresa: "",
        id_processo: "",
        id_tipo_evento: "",
        processa: ""
      }
    };
  },

  watch: {
    dialog(value) {
      return value;
    },

    labelBtn(value) {
      return value;
    },

    async editVinculo() {
      if (this.editVinculo) {
        this.formVinculo = this.editVinculo;
        this.formVinculo.id_empresa = parseInt(this.formVinculo.id_empresa);
      }
    }
  },

  computed: {
    ...mapGetters({
      getEmpresas: "empresa/getEmpresas",
      getProcedures: "procedure/getProcedures",
      getTiposEvento: "comissao/getTiposEvento"
    })
  },

  methods: {
    ...mapActions({
      fetchEmpresas: "empresa/fetchEmpresas",
      fetchProcedures: "procedure/fetchProcedures",
      fetchTiposEvento: "comissao/fetchTiposEvento"
    }),

    async vincularEmpresaProcesso() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        try {
          await serviceProcedure.postEmpresasByProcessos(this.formVinculo),
            this.$notify({
              type: "success",
              text: "Processo adicionado"
            });
        } catch (error) {
          this.$notify({
            type: "error",
            text: "Erro ao adicionar Processo"
          });
          throw new Error(error);
        } finally {
          this.close();
        }
      } else {
        this.$notify({
          type: "error",
          text: "Formulário inválido"
        });

        throw new Error("Formulário inválido");
      }
    },

    async editarVinculo() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        try {
          await serviceProcedure.putEmpresasByProcessos(
            this.formVinculo.id_processo,
            this.formVinculo
          ),
            this.$notify({
              type: "success",
              text: "Processo editado"
            });
        } catch (error) {
          this.$notify({
            type: "error",
            text: "Erro ao editar Processo"
          });
          throw new Error(error);
        } finally {
          this.close();
        }
      } else {
        this.$notify({
          type: "error",
          text: "Formulário inválido"
        });
        throw new Error("Formulário inválido");
      }
    },

    close() {
      // this.formVinculo = this.defaultStateForm;
      this.$refs.form.reset();
      this.$emit("close");
    }
  },
  mounted() {
    this.fetchEmpresas({ id_band: "2,4" });
    this.fetchProcedures();
    this.fetchTiposEvento();
  }
};
</script>

<style></style>
