<template>
  <div>
    <BaseTable
      :headers="headers"
      :items="empresaProcessa"
      :loading="loading"
      :paginate="true"
      sort-by="nome_empresa"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Processos</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <RegisterBottom
            v-can-access="166"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
          <FiltroEmpresaProcessaVue
            @selectedFilters="getEmpresaProcessa($event)"
          />
          <ModalCadastroEmpresaProcessa
            :dialog="dialog"
            :labelBtn="labelBtn"
            :editVinculo="vinculaEmpresa"
            @close="dialog = false"
          />
        </v-toolbar>
      </template>
      <template v-slot:[`item.nome_empresa`]="{ item }">
        {{ item.nome_empresa | TitleCase }}
      </template>

      <template v-slot:[`item.descricao`]="{ item }">
        {{ item.descricao | TitleCase }}
      </template>

      <template v-slot:[`item.tipo_evento`]="{ item }">
        {{ item.tipo_evento | TitleCase }}
      </template>

      <template v-slot:[`item.processa`]="{ item }">
        <v-icon :color="statusFormatedIcon(item.processa).color">
          {{ statusFormatedIcon(item.processa).icon }}
        </v-icon>
      </template>

      <template v-slot:[`item.ativo`]="{ item }">
        <v-chip
          :color="statusProcesso(item.ativo) == 'Ativo' ? 'green' : 'red'"
          dark
        >
          {{ statusProcesso(item.ativo) }}
        </v-chip>
      </template>
      <template v-slot:[`item.acoes`]="{ item }">
        <!-- <v-btn icon v-can-access="165" @click="editItem(item)" color="orange" dark>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          v-can-access="167"
          icon
          @click="deleteItem(item)"
          class="ml-2"
          color="red"
          dark
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>-->

        <IconBottom :name="'edit'" v-can-access="165" @click="editItem(item)" />
        <IconBottom
          :name="'delete'"
          v-can-access="167"
          @click="deleteItem(item)"
        />
      </template>
    </BaseTable>
  </div>
</template>

<script>
import dateMixin from "@/mixins/dateMixin";
import BaseTable from "@/components/shared/NewBaseTable.vue";
import ModalCadastroEmpresaProcessa from "@/components/sistema/processos/ModalCadastroEmpresaProcessa";
import serviceProcedure from "@/services/procedures";
import IconBottom from "@/components/shared/bottons/IconBottom";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import FiltroEmpresaProcessaVue from "./filtroEmpresaProcessa.vue";
export default {
  components: {
    BaseTable,
    ModalCadastroEmpresaProcessa,
    RegisterBottom,
    FiltroEmpresaProcessaVue,
    IconBottom
  },

  mixins: [dateMixin],

  data() {
    return {
      labelBtn: "Salvar",
      dialog: false,
      vinculaEmpresa: {},
      loading: false,
      lastPage: 1,
      actualPage: 1,
      headers: [
        { text: "Bandeira", value: "nome_bandeira" },
        { text: "Apelido", value: "apelido" },
        { text: "Empresa", value: "nome_empresa" },
        { text: "Processos", value: "descricao" },
        { text: "Tipo de Evento", value: "tipo_evento" },
        { text: "Processa", value: "processa" },
        { text: "Status", value: "ativo" },
        { text: "Ações", value: "acoes", align: "center" }
      ],
      empresaProcessa: [],
      formatedIcon: {
        S: {
          icon: "mdi-check",
          color: "success"
        },
        N: {
          icon: "mdi-close",
          color: "error"
        }
      }
    };
  },

  methods: {
    statusFormatedIcon(item) {
      return this.formatedIcon[item];
    },

    async getEmpresaProcessa(filters) {
      try {
        const response = await serviceProcedure.getEmpresasByProcessos({
          per_page: -1,
          ...filters
        });
        this.empresaProcessa = response.data.data;
        this.lastPage = response.data.last_page;
        this.actualPage = 1;
      } catch (error) {
        console.error(error);
      }
    },

    statusProcessa(item) {
      if (item == "S") item = "Sim";
      if (item == "N") item = "Não";

      return item;
    },

    statusProcesso(item) {
      if (item == "S") item = "Ativo";
      if (item == "N") item = "Inativo";

      return item;
    },

    editItem(item) {
      this.vinculaEmpresa = Object.assign({}, item);
      this.labelBtn = "Editar";
      this.dialog = true;
    },

    async deleteItem(item) {
      await this.$swal.confirm(
        "Deletar processo",
        `Deseja deletar o processo ${item.descricao} da empresa ${item.apelido} no evento ${item.tipo_evento}?`
      );
      try {
        await serviceProcedure.deleteProcessos(item.id_processo);
        this.$notify({
          type: "success",
          text: "Processo deletado"
        });
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro ao deletar Processo"
        });
      } finally {
        await this.getEmpresaProcessa();
      }
    }
  },

  mounted() {
    this.getEmpresaProcessa();
  }
};
</script>

<style></style>
