import BaseServiceHttp from "./BaseServiceHttp";
class Comissao extends BaseServiceHttp {
  constructor(id) {
    const resource = "comissoes";
    let relationship = {
      baseCalculo: "base-calculo",
      baseComparativa: "base-comparativa",
      colaboradorEmpresaIndicador: "colaborador-empresa-indicador",
      colaboradorEquipe: "colaborador-equipe",
      comissaoEquipe: "comissao-equipe",
      cargos: "cargos",
      comissao: "comissoes",
      consorcio: "consorcio",
      departamentos: "departamentos",
      descritivo: "descritivo",
      equipeSecao: "equipe-secao",
      empresaCota: "empresa-cota",
      funcoesParametrosDepartamentos: "funcoes-parametros-departamentos",
      funcoesParametrosEquipes: "funcoes-parametros-equipes",
      funcaoParametrosDepartamentos: "funcoes-parametros-departamentos",
      funcaoParametrosEquipes: "funcoes-parametros-equipes",
      grupoComissao: "grupo-comissao",
      metaEmpresa: "meta-empresa",
      metaEmpresaFuncao: "meta-empresa-funcao",
      metaEquipe: "meta-equipe",
      metaFuncao: "meta-funcao",
      metaIndividual: "meta-individual",
      metaPdv: "meta-pdv",
      metaProgressivaFuncao: "meta-progressiva-funcao",
      metaSemanaEquipe: "meta-semana-equipe",
      metaSemanaIndividual: "meta-semana-individual",
      lancamentoManual: "lancamento-manual",
      parametrosDatas: "parametros-datas",
      parametrosDepartamentos: "parametros-departamentos",
      parametrosEmpresas: "parametros-empresas",
      parametrosEquipes: "parametros-equipes",
      parametrosPosVendas: "parametros-pos-venda",

      procedures: "procedures",
      processa: "processa",
      processos: "processos",
      processos2: "procedures/processos",

      processosParametros: "processos-parametros",
      realizadoEmpresa: "realizado-empresa",
      realizadoEmpresaTrimestre: "realizado-empresa-trimestre/quantidade",
      realizadoEquipe: "realizado-equipe",
      realizadoEquipeSemana: "realizado-equipe-semana",
      realizadoIndividual: "realizado-individual",
      resumoEquipe: "resumo-equipe",
      resumoVendedor: "resumo-vendedor",
      resumoVeiculo: "resumo-veiculo",
      resumoMapaGeral: "resumo-mapa-geral",
      secao: "secao",
      statusConsorcio: "status-consorcio",
      tipoClasses: "tipos-classes",
      tipoComissao: "tipo-comissao",
      tipoComparativo: "tipo-comparativo",
      tipoDados: "tipo-dados",
      tipoEquipe: "tipo-equipe",
      tipoEvento: "tipo-evento",
      tipoFechamento: "tipo-fechamento",
      tipoOS: "tipo-os"
    };
    super(id, resource, relationship);
  }
}

export default id => new Comissao(id);
