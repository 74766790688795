import http from "../http";
import toTitle from "@/utils/toTitle";
const request_type = {
  show: "get",
  update: "put",
  store: "post",
  delete: "delete",
  list: "get"
};

export default class BaseService {
  uri = "";
  method = "";
  form = {};
  relationship;
  selected_rel = "";
  requests;

  constructor(id, relationship, resource) {
    this.id = id ? `/${id}` : "";
    this.relationship = relationship;
    this.resource = resource;
    let base = `${this.uri}/${this.resource}${this.id}`;
    Object.keys(relationship).map(index => {
      this.generateRelationships(index, base);
    });
    Object.keys(request_type).map(index => this.assignMethods(base, index));
  }

  generateRelationships(index, base) {
    this.selected_rel = this.relationship[index];
    Object.keys(request_type).map(key => {
      this.assignMethods(base, `${index}${toTitle(key)}`);
    });
  }

  assignMethods(base, index) {
    return Object.assign(BaseService.prototype, {
      [index]: this.generateRequests(base, index)
    });
  }

  generateRequests(base, index) {
    let resource = index.split(/(?=[A-Z])/);
    resource = resource[1]?.toLowerCase() || "";
    if (
      Object.keys(request_type)
        .map(j => j)
        .includes(index) ||
      Object.keys(request_type)
        .map(j => j)
        .includes(resource) ||
      index.endsWith("Store")
    ) {
      return (form = {}) => {
        this.generateURI(base, index, null);
        return this.checkParams(form);
      };
    }
    return (args = "", form = {}) => {
      this.generateURI(base, index, args);
      return this.checkParams(form);
    };
  }

  async checkParams(form) {
    if (form && this.method == "get") form = { params: { ...form } };
    if (JSON.stringify(form) != "{}" && this.method == "delete") {
      this.uri = this.uri + form;
      return http[this.method](this.uri);
    }
    return http[this.method](this.uri, form);
  }

  generateURI(base, index, args) {
    let resource = index.split(/(?=[A-Z])/);
    this.method = request_type[this.checkTypeResource(index)];
    let baseRel = args
      ? `${base}/${this.selected_rel}/${args}`
      : `${base}/${this.selected_rel}`;
    this.uri = resource.length == 1 ? base : baseRel;
  }

  checkTypeResource(resource) {
    let checked;
    Object.keys(request_type).map(j => {
      let res = resource.toLowerCase().match(j);
      if (res) checked = res[0];
    });
    return checked;
  }
}
