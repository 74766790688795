<template>
  <BaseBottom
    v-bind="$attrs"
    v-on="$listeners"
    icon
    :colorIcon="buttonIcons[name].color"
    :iconName="buttonIcons[name].icon"
  />
</template>

<script>
import BaseBottom from "./BaseBottom.vue";

export default {
  name: "IconBottom",
  components: {
    BaseBottom
  },

  props: {
    name: {
      type: String
    }
  },

  computed: {
    buttonIcons() {
      return {
        edit: {
          icon: "mdi-pencil",
          color: "orange"
        },
        delete: {
          icon: "mdi-trash-can",
          color: "red"
        },
        view: {
          icon: "mdi-eye",
          color: "primary"
        },
        company: {
          icon: "mdi-domain",
          color: "green"
        },
        timeline: {
          icon: "mdi-timeline-check-outline",
          color: "primary"
        },
        document: {
          icon: "mdi-file-document-outline",
          color: "red"
        },
        bond: {
          icon: "mdi-swap-horizontal",
          color: "primary"
        }
      };
    }
  },
  methods: {
    botoes(btn) {
      let button = {
        edit: {
          icon: "mdi-pencil",
          color: "orange"
        },
        delete: {
          icon: "mdi-trash-can",
          color: "red"
        },
        view: {
          icon: "mdi-eye",
          color: "primary"
        },
        company: {
          icon: "mdi-domain",
          color: "green"
        },
        timeline: {
          icon: "mdi-timeline-check-outline",
          color: "primary"
        },
        document: {
          icon: "mdi-file-document-outline",
          color: "red"
        },
        bond: {
          icon: "mdi-swap-horizontal",
          color: "primary"
        }
      };
      return { icon: button[btn]?.icon, color: button[btn]?.color };
    }
  }
};
</script>
