<template>
  <v-menu :close-on-content-click="false" max-width="320" offset-x>
    <template v-slot:activator="{ on }">
      <FilterBottom v-on="on" />
    </template>

    <v-card>
      <v-container>
        <BaseFilter
          prepend-inner-icon="mdi-file-table-box-multiple-outline"
          clearable
          single-line
          label="Segmento"
          :filters="{ id_band: '2,4' }"
          item-text="descricao"
          item-value="id_band"
          service="sistemaService.tipoBandeiras"
          v-model="filter.id_band"
          @change="handleFilter()"
          :isCompany="true"
        />

        <BaseFilter
          prepend-inner-icon="mdi-file-table-box-multiple-outline"
          clearable
          single-line
          label="Empresa"
          item-text="apelido"
          item-value="id_empresa"
          service="sistemaService.empresa"
          v-model="filter.id_empresa"
          @change="handleFilter()"
          :isCompany="true"
        />
        <BaseFilter
          prepend-inner-icon="mdi-file-table-box-multiple-outline"
          clearable
          single-line
          label="Processos"
          item-text="descricao"
          item-value="id_processo"
          service="comissaoService.processos2"
          v-model="filter.id_processo"
          @change="handleFilter()"
        />
        <BaseFilter
          prepend-inner-icon="mdi-file-table-box-multiple-outline"
          clearable
          single-line
          label="Tipo Evento"
          item-text="tipo_evento"
          item-value="id_tipo_evento"
          service="comissaoService.tipoEvento"
          v-model="filter.id_tipo_evento"
          @change="handleFilter()"
        />
        <!-- <v-select
          :items="itensProcessos"
          item-text="descricao"
          item-value="id_processo"
          label="Standard"
          dense
        ></v-select> -->
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
import BaseFilter from "@/components/shared/BaseSelectService";
import FilterBottom from "@/components/shared/bottons/FilterBottom";
export default {
  components: { BaseFilter, FilterBottom },
  data() {
    return {
      filter: {}
    };
  },
  methods: {
    handleFilter() {
      this.$emit("selectedFilters", this.filter);
    }
  }
};
</script>
